import React, { Component }              from 'react';
import ImgTransformation                 from '../img/bg-trans.png';
import ImgButton                         from '../img/bt-circle.png';
import { IconsTransformation as IconsT } from '../img/iconsTransformation';
import './style.css';

const ListContent = [
    {
        title: 'Relación con los clientes',
        subtitle: 'Experiencias convincentes en todos los canales',
        content: 'El cliente como punto primario de todos los procesos de la organización, en los cuales forma parte o es interesado. La propuesta es medir, evaluar y mejorar continuamente la forma con la cual se conecta la institución con el usuario, generando mejores experiencias que cumplan con las expectativas y fidelicen la relación con el cliente.',
        icon: IconsT.t1,
        quarterSection: [
            {
                title: 'Información',
                content: 'Empezar a utilizar los datos que genera y obtiene el negocio.',
            }, {
                title: 'Fidelización',
                content: 'Retener clientes a través de mejores experiencias.',
            }, {
                title: 'Captación',
                content: 'Entender los procesos y flujos de entrada de usuarios en el ecosistema',
            }, {
                title: 'Medición',
                content: 'Evaluación continua de las iteraciones con el cliente para acompañar el dinamismo de los ecosistemas actuales.',
            },
        ],
    }, {
        title: 'El empoderamiento de los recursos de la organización',
        subtitle: '',
        content: 'También las personas que conforman la organización deben encontrarse con un ambiente que los motive a brindar un servicio de excelencia y conformar una mejor experiencia para el usuario.\n' +
                 'Para ello es necesario contar con las herramientas correctas y maximizando sus prestaciones, brindando todos los elementos necesarios para la apoyatura por parte de los empleados y una correcta toma de decisiones. ',
        icon: IconsT.t2,
        quarterSection: [
            {
                title: 'Conocimiento',
                content: 'Correcta administración del conocimiento en la institución.',
            }, {
                title: 'Seguridad',
                content: 'Disponibilizar los medios para resguardar acceso a servicios e información, cumpliendo con los requerimientos del negocio.',
            }, {
                title: 'Escalabilidad',
                content: 'Permitir que el negocio crezca de acuerdo al ritmo exigido por el cliente y mercado.',
            }, {
                title: 'Herramientas',
                content: 'Deben ser de fácil acceso pero con los niveles de seguridad necesarios, pensada desde los paradigmas actuales de colaboración.',
            },
        ],
    }, {
        title: 'La optimización de procesos y operaciones',
        subtitle: '',
        content: 'Es fundamental revisar y adecuar los procesos del negocio de forma continua, La tecnología facilita la reducción de costos, mitigación de errores y prevención de fraudes que pueden existir por procesos definidos en diferentes momentos de la compañía. Poder evolucionar con el negocio y mejorar aspectos no identificados oportunamente es una práctica saludable y necesaria para mantener positivos los números de las empresas.',
        icon: IconsT.t3,
        quarterSection: [
            {
                title: 'Revisión',
                content: 'Entender qué elementos pueden ser automatizados y proponer cambios.',
            }, {
                title: 'Capacitación',
                content: 'Dotar de conocimientos a la estructura para poder implementar mejoras en los circuitos de trabajo.',
            }, {
                title: 'Identificación',
                content: 'Revisar problemas y situaciones desatendidas a lo largo del tiempo.',
            },
        ],
    }, {
        title: 'La evolución del negocio',
        subtitle: 'Innovación y generación de valor',
        content: 'Las nuevas plataformas digitales agregan elementos que disparan nuevos escenarios, facilitando la innovación, tanto a nivel de generar nuevos productos y servicios como en aumentar la calidad en la oferta de la institución a sus clientes. Brinda elementos para aumentar la oferta al mercado y generar valor.',
        icon: IconsT.t4,
        quarterSection: [
            {
                title: 'Conexión',
                content: 'Interconectar la instituciones con otros jugadores, ampliando la propuesta de valor de la compañía, e integrándola con otras necesidades o deseos del cliente, mediante modelos de APIs abiertas.',
            }, {
                title: 'Nuevos Paradigmas',
                content: 'Entender los nuevos paradigmas tecnológicos: Biometria, Blockchain, BigData, entre otros.',
            }, {
                title: 'Soluciones',
                content: 'Creación de nuevos productos y servicios.',
            },
        ],
    },
];

class ButtonCircle extends Component {
    handleClick = () => (this.props.onClick(this.props.index));
    render() {
        const { content, icon, index } = this.props;
        const left = index * 25;
        return (
            <div className={ 'btn-custom lf-' + left } key={`btnc-${index}`}>
                <button className={ this.props.isActive ?
                                    'bt-circle active' :
                                    'bt-circle' } onClick={ this.handleClick }>
                    <img src={ ImgButton } alt="img-button"
                         className={ 'img-button' }/>
                    <div className="bt-content is-hidden-touch">
                        { content }
                    </div>
                    <div className="bt-icon is-hidden-desktop">
                        <img src={ icon } alt="Transformation" />
                    </div>
                </button>
            </div>
        );
    }
}

const QuarterSegment = ({ title, content, id }) => (
    <div className="column is-half" key={ id }>
        <div className={ 'title-qsegment' }>
            { `✓ ${title}` }
        </div>
        <p className={ 'content-qsegment' }>
            { content }
        </p>
    </div>
);
const SegmentCircle = ({ icon, title, subtitle, content, quarterSection, index, isActive }) => (
    <div className={ isActive ?
                     'segment-info columns is-multiline' :
                     'segment-info columns is-multiline is-hidden'  } key={ `si${index}` }>
        <div className="column is-full">
            <img src={ icon } alt="Icon Transformation" className={'icon-transformation'}/>
        </div>
        <div className="column is-half">
            <div className="title">
                { title }
            </div>
            <div className="subtitle">
                { subtitle }
            </div>
            <p className="content">
                { content }
            </p>
        </div>
        <div className="column is-half">
            <div className="columns is-multiline quarter-sections">
                {
                    quarterSection.map(({ title, content }, key) =>
                        <QuarterSegment title={ title } content={ content }
                                        id={ key } key={key}/>,
                    )
                }
            </div>
        </div>
    </div>
);

class Transformation extends Component {
    constructor() {
        super();
        this.state = {
            activeIndex: null,
        };
    }

    handleClick = (index) => this.setState({ activeIndex: index });

    render() {
        return (
            <div id="transformation">
                <div className="margin-content segment-one">
                    <h2>Transformación</h2>
                    <div className="description">
                        <p>
                            Creemos en el valor cuando acompaña al negocio. Es
                            por eso que trabajamos sobre las necesidades que
                            tiene el negocio aportando valor desde la
                            transformación digital. Colaboramos con nuestros
                            clientes para un diseño estratégico seguro y de
                            rápida ejecución, brindando resultados con retornos
                            más eficientes, tanto en tiempos como en inversión.
                        </p>
                    </div>
                    <div className="img-transformation">
                        {
                            ListContent.map(({title, icon}, key) =>
                                <ButtonCircle content={ title } icon={icon} index={ key }
                                              isActive={ this.state.activeIndex ===
                                                         key }
                                              onClick={ this.handleClick }
                                              key={key}  />,
                            )
                        }
                        <img src={ ImgTransformation } alt="transformation" />
                    </div>
                </div>
                <div className="info-detail">
                    <div className="margin-content">
                        {
                            ListContent.map((
                                { title, subtitle, content, icon, quarterSection },
                                key) =>
                                <SegmentCircle title={ title }
                                               subtitle={ subtitle }
                                               content={ content } icon={ icon }
                                               quarterSection={ quarterSection }
                                               index={ key }
                                               isActive={ this.state.activeIndex ===
                                                          key }
                                               key={key}
                                />,
                            )
                        }
                    </div>
                </div>
                <div className="cite background-geometry">
                    <div className="more-margin elements">
                        <div className="description">
                            <p>
                                <span className="comillas open" />
                                Nos sentimos conectados, preparados para crear
                                experiencias diferenciales, para transformar,
                                para hacer que las cosas ocurran
                                <span className="comillas" />
                            </p>
                        </div>
                    </div>
                    <div id="services" />
                </div>
            </div>
        );
    }
}

export default Transformation;
