import React, { Component } from 'react';
import City from '../img/city.png'
import './style.css';

class Home extends Component {
    render() {
        return (
            <div>
                <div id="home" className="segment home">
                    <div className="content-city margin-content">
                        <div className="text-main">
                            Be adaptive in the path<br />of transformation
                        </div>
                        <div className="text-second">
                            Reinventamos las plataformas tecnológicas,
                            humanizando
                            la experiencia y creando<br />nuevos procesos que
                            evolucionen la relación con los clientes
                        </div>
                    </div>
                    <div className="img-city margin-content">
                        <img src={City} alt="City Estartia" className={'city'} />
                    </div>
                </div>
                <div className="slogan background-geometry">
                    <div className="text margin-content">
                        Somos consultores expertos en transformación digital y
                        seguridad informática, con una mirada localizada en
                        agregar valor en el próximo paso de su negocio.
                    </div>
                </div>
                <div className="our-us segment-triangle " id="our-values">
                    <div className="margin-content elements">
                        <div className="title right">
                            <h2 className="underline">Nuestros valores</h2>
                        </div>
                        <div className="description">
                            <p>
                                Creemos en el valor cuando acompaña al negocio. Es
                                por eso que trabajamos sobre las necesidades que
                                tiene el negocio aportando valor desde la
                                transformación digital. Colaboramos con nuestros
                                clientes para un diseño estratégico seguro y de
                                rápida ejecución, brindando resultados con retornos
                                más eficientes, tanto en tiempos como en inversión.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
