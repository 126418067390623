import React, { Component } from 'react';
import Menu                 from './component/elements/menu';
import Home                 from './component/home';
import WeDo                 from './component/weDo';
import Services             from './component/services';
import Footer               from './component/footer';
import Transformation       from './component/transformation';
import Contact              from './component/contact';
// import Partners              from './component/partners';

import './css/main.css';

class Main extends Component {
    constructor() {
        super();
        this.state = {
            nameClass: '',
        }
    }
    componentDidMount() {
        window.addEventListener('scroll', this.listenScrollEvent)
    }
    listenScrollEvent = e => {
        if (window.scrollY > 100) {
            this.setState({nameClass: 'scroll'})
        } else {
            this.setState({nameClass: ''})
        }
    }

    render() {
        const nameClass = this.state.nameClass;
        return (
            <div>
                <header className={nameClass}>
                    <Menu />
                </header>
                <div className="content">
                    <Home />
                    <WeDo />
                    <Transformation />
                    <Services />
                    {/*<Partners />*/}
                    <Contact />
                    <Footer />
                </div>
            </div>
        );
    }
}

export default Main;
