import React, { Component } from 'react';
import { ContactIcons }     from '../img/contact';
import './style.css';

const data = [
    {
        icon: ContactIcons.c1,
        detail: '5960 SW 57th Avenue',
        detail2: '33143, Miami, Florida',
        detail3: 'United States',
    }, {
        icon: ContactIcons.c3,
        detail: 'info@estartia.com',
    },
];
/*const Address = ({ icon, detail, detail2, detail3, id }) => (
    <div className="columns item-contact is-multiline" key={ id }>
        <div className="icon column is-full">
            <img src={ icon } alt="icon" />
        </div>
        <div className="description column is-10 is-offset-1">
            { detail }
            <br />
            { detail2 }
            <br />
            { detail3 }
        </div>
    </div>
);*/

class Contact extends Component {
    render() {
        return (
            <div id="contact">
                <div className="columns margin-content is-multiline">
                    <h4 className="column is-full mb120">¡Aquí estamos!</h4>
                    <div className="column is-7 is-offset-1 box-white">
                        <h5>Envianos un email y nos pondremos en contacto con
                            vos</h5>
                        <div className="columns is-multiline form-contact">
                            <div className="column is-half">
                                <input type="text" placeholder="Nombre" />
                            </div>
                            <div className="column is-half">
                                <input type="text" placeholder="Email" />
                            </div>
                            <div className="column is-half">
                                <input type="text" placeholder="Teléfono" />
                            </div>
                            <div className="column is-half">
                                <input type="text" placeholder="Web Site" />
                            </div>
                            <div className="column is-full">
                                <textarea placeholder="Mensaje" />
                            </div>
                            <div className="column is-full">
                                <button>Enviar</button>
                            </div>
                        </div>

                    </div>
                    <div className="column is-3 box-white">
                        <h5>Contacto</h5>
                        <div className="columns item-contact is-multiline">
                            <div className="icon column is-full">
                                <img src={ data[0].icon } alt="icon" />
                            </div>
                            <div className="description column is-full">
                                { data[0].detail }
                                <br />
                                { data[0].detail2 }
                                <br />
                                { data[0].detail3 }
                            </div>
                        </div>
                        <div className="columns item-contact is-multiline">
                            <div className="icon column is-full">
                                <img src={ data[1].icon } alt="icon" />
                            </div>
                            <div className="description column is-full">
                                <a href={"mailto:"+data[1].detail} target="_top">{ data[1].detail }</a>
                            </div>
                        </div>
                        { /*<div className="icons-social">*/ }
                        { /*{*/ }
                        { /*IconSocial.map(({ icon }, key) =>{*/ }
                        { /*return (*/ }
                        { /*<a key={ key }>*/ }
                        { /*<img src={ icon } alt='icon social' />*/ }
                        { /*</a>*/ }
                        { /*)*/ }
                        { /*}*/ }
                        { /*)*/ }
                        { /*}*/ }
                        { /*</div>*/ }
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;
