export const  WeDoIcons ={
    c1: require('./c1.png'),
    c2: require('./c2.png'),
    c3: require('./c3.png'),
    o1: require('./o1.png'),
    o2: require('./o2.png'),
    o3: require('./o3.png'),
    t1: require('./t1.png'),
    t2: require('./t2.png'),
    t3: require('./t3.png'),
};
