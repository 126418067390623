import React, {Component} from 'react'
import "./style.css"

const year = new Date().getFullYear();
export default class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="footer-content">
                    <p className="copyright">
                        {year}. Estartia. <br className="is-hidden-tablet"/>All Rights Reserved.
                    </p>
                    <div className="qr">
                        <a href="http://qr.afip.gob.ar/?qr=3TFAYzZCNwhHTXI5aYx5Wg,," target="_F960AFIPInfo">
                            <img src="http://www.afip.gob.ar/images/f960/DATAWEB.jpg" border="0" />
                        </a>
                    </div>
                </div>
            </footer>
        )
    }
}
