import React, { Component }   from 'react';
import { TabsComponent }               from './tabs';
import { WeDoIcons } from "../img/weDo"
import { IconsWeDo } from "../img/iconsWeDo"
import './style.css';

const tabList = [
    {
        name: 'Consultoría',
        image: IconsWeDo.wd1,
        icons: [
            {
                icon:WeDoIcons.c1,
                title:'Relevamiento e investigación del negocio y los procesos en conjunto con el cliente.'
            }, {
                icon: WeDoIcons.c2,
                title: 'Asesoramiento para mejorar su actividad y gestión.'
            },{
                icon:WeDoIcons.c3,
                title:'Creación de la propuesta de valor alineando objetivos a la era digital.'
            }
        ],
        content: [
            "Nuestro objetivo es ayudar a las empresas a lograr su mejor performance mediante el asesoramiento, impulsándolas a mejorar su actividad y la gestión en el día a día. Somos conscientes de que las organizaciones requieren soluciones que les permitan crecer. Crear propuestas de valor que alineen los objetivos del negocio con la revolución digital que vive la sociedad es la clave del éxito que permitirá a las empresas diferenciarse en un mercado cada vez más voluble."
        ],
    }, {
        name: 'Tecnología',
        image: IconsWeDo.wd2,
        icons: [
            {
                icon:WeDoIcons.t1,
                title:'Identificacamos aspiraciones, necesidades y deseos del consumidor fnal.'
            }, {
                icon:WeDoIcons.t2,
                title: 'Construímos productos digitales eficientes y duraderos.'
            },{
                icon:WeDoIcons.t3,
                title:'Diseñamos arquitecturas sólidas, adaptables y eficaces.'
            }
        ],
        content: [
            "La experiencia se está convirtiendo en la fuerza fundamental de una transformación digital cada vez más relacionada con las personas. Nuestra estrategia está basada en identificar las aspiraciones, necesidades y deseos de nuestros clientes y así, ser capaces de construir productos digitales que sean eficientes y permanezcan activos en el tiempo. Este proceso permite generar interfaces capaces de transmitir emociones que lleven a la acción y lograr los objetivos establecidos.",
            "A su vez, diseñamos arquitecturas sólidas que son la base de nuestras soluciones, adaptables, que brindan una respuesta eficaz al crecimiento y cambios que viven los distintos sectores de la economía, mientras integramos la importancia del dato a través de diferentes sistemas."
        ],
    }, {
        name: 'Operaciones',
        image: IconsWeDo.wd3,
        icons: [
            {
                icon:WeDoIcons.o1,
                title:'Ofrecemos excelencia operativa.'
            }, {
                icon: WeDoIcons.o2,
                title: 'Experiencia.'
            },{
                icon:WeDoIcons.o3,
                title:'Capacidad de transformación end2end.'
            }
        ],
        content: [
            "Los mercados han adquirido una madurez notable en términos de industrialización. Sin embargo, en el contexto de cambio constante que caracteriza a la economía y sociedad digital, se enfrentan a numerosos desafíos: la falta de innovación práctica orientada al negocio, o los modelos operativos no compatibles con el negocio digital.",
            "Por nuestra parte, entendemos que la mejor respuesta es la que se ofrece a través de una combinación de excelencia operativa, experiencia y capacidad de transformación. En Estartia ofrecemos soluciones basadas en productos y servicios que garantizan la renovación tecnológica. Trabajamos en un objetivo común con cada uno de nuestros clientes, a partir del mayor de los compromisos, transformando su cultura digital y logrando ventajas competitivas que les permitan destacarse como empresas del siglo XXI."
        ],
    }
];

class WeDo extends Component {
    render() {
        return (
            <div id="we-do" className="grey cut-segment">
                <div className="margin-content columns is-multiline">
                    <h2 className="column is-full">Qué hacemos</h2>
                    <p className="column is-full text-center">
                        Seleccionamos las mejores soluciones para acelerar el
                        proceso de la transformación digital.
                    </p>
                    <div className="tabs is-centered column">
                        <TabsComponent data={tabList} />
                    </div>
                </div>
            </div>
        );
    }
}

export default WeDo;
