import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import smoothscroll         from 'smoothscroll-polyfill';
import Logo                 from '../../img/logo.png';
import LogoBlack                 from '../../img/logo-gris.png';
import './menu.css';

export default class Menu extends Component {
    constructor() {
        super();
        this.FuncScroll = this.FuncScroll.bind(this);
        this.handleNavbar = this.handleNavbar.bind(this);
        this.state = {
            nameClass: 'initial',
        };
    }
    handleNavbar() {
        const burger = document.querySelector('.burger');
        const nav = document.querySelector('#' + burger.dataset.target);
        burger.classList.toggle('is-active');
        nav.classList.toggle('is-active');
    }
    listenScrollEvent = e => {
        if (window.scrollY > 100) {
            this.setState({ nameClass: 'scroll' });
        } else {
            this.setState({ nameClass: 'initial' });
            document.querySelector('logo-dark')
        }
    };

    componentDidMount() {
        window.addEventListener('scroll', this.listenScrollEvent);
    }

    FuncScroll = (el) => {
        el.scrollIntoView({  behavior: 'smooth', block: 'start' });
        smoothscroll.polyfill();
    };

    render() {
        return (
            <nav className={ 'navbar' } role="navigation"
                 aria-label="main navigation">
                <div className="navbar-brand">
                    <div className="navbar-item">
                        <Link smooth to="#home" scroll={ this.FuncScroll }>
                            <img src={ Logo } alt="Logo" className={'logo-white'}/>
                            <img src={ LogoBlack } alt="Logo" className={'logo-dark'}/>
                        </Link>
                    </div>
                    <span role="button" className="navbar-burger burger"
                          aria-label="menu" aria-expanded="false"
                          data-target="navbarBasic"
                          onClick={ this.handleNavbar }>
                                <span aria-hidden="true" />
                                <span aria-hidden="true" />
                                <span aria-hidden="true" />
                            </span>
                </div>
                <div id="navbarBasic" className="navbar-menu">
                    <div className="navbar-end">
                        <ul className="menu-custom">
                            <li><Link smooth to="#our-values"
                                      scroll={ this.FuncScroll }>Valores</Link>
                            </li>
                            <li><Link smooth to="#we-do"
                                      scroll={ this.FuncScroll }>Qué
                                                                 Hacemos</Link>
                            </li>
                            <li><Link smooth to="#transformation"
                                      scroll={ this.FuncScroll }>Transformación</Link>
                            </li>
                            <li><Link smooth to="#services"
                                      scroll={ this.FuncScroll }>Servicios</Link>
                            </li>
                            {/*<li><Link smooth to="#partners"*/}
                                      {/*scroll={ this.FuncScroll }>Partners</Link>*/}
                            {/*</li>*/}
                            <li><Link smooth to="#contact"
                                      scroll={ this.FuncScroll }>Contacto</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}
