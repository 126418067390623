import React, { Component } from 'react';
import ImgService from '../img/service.png'
import { ServicesIcons } from '../img/services'
import './style.css';

const info = [
    {
        id: 1,
        icon: ServicesIcons.s1,
        title: 'Transformación digital',
        content: 'Trabajamos en el proceso de transformación digital de empresas, acompañando el camino desde el entendimiento de los procesos actuales hasta el diseño e implementación de las nuevas estrategias.',
    }, {
        id: 2,
        icon: ServicesIcons.s2,
        title: 'Seguridad informática',
        content: 'Desde la identificación de necesidades hasta el diseño e implementación de las políticas y procesos de remediación y mitigación de los principales riesgos del negocio. Trabajamos con una mirada estratégica y global sobre la organización.',
    }, {
        id: 3,
        icon: ServicesIcons.s3,
        title: 'Arquitectura y Productización',
        content: 'Rediseñamos procesos y aplicaciones con la finalidad de mejorar el rendimiento. Diseñamos e implementamos RoadMaps de Producto con nuestros clientes, priorizando el enfoque del negocio.',
    },
];
const Service = (props) => {
    const data = props.data;
    return (
        <div className="column service is-one-third">
            <div className="icon-service">
                <img src={ data.icon } alt="icon" />
            </div>
            <div className="content-service">
                <div className="title">
                    { data.title }
                </div>
                <div className="description">
                    <p className="text-center">{ data.content }</p>
                </div>
            </div>
        </div>
    );
};

class Services extends Component {
    render() {
        return (
            <div id="services" className="services segment-triangle-trans">
                <div className="margin-content">
                    <h2>Servicios</h2>
                    <p className="text-center">
                        Buscamos la combinación de excelencia
                        operativa, experiencia y capacidad de
                        transformación.
                    </p>
                    <div className="columns is-multiline">
                        <div className="img-services column is-full is-paddingless">
                            <img src={ ImgService } alt="services" className={'city'} />
                        </div>
                        <div className="group-services column is-full">
                            <div className="columns">
                                { info && info.map((item) =>
                                    <Service data={ item } key={ item.id } />,
                                ) }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Services;
