import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";

export const TabsComponent = ({data}) => (
    <Tabs>
        <TabList>
            {
                data && data.map(({name, image}, key)=>
                    <Tab key={"tab"+key}>
                        <div className="is-hidden-touch">
                            { name }
                        </div>
                        <div className="is-hidden-desktop image-we-do">
                            <img src={image} alt="Icon Tab" />
                        </div>
                    </Tab>
                )
            }
        </TabList>
        { data && data.map(({name, icons, content}, key)=>
            <TabPanel key={"tp"+key}>
                <div className="is-hidden-desktop">
                    <h4>{ name }</h4>
                </div>
                {
                    content.map((data, key) =>
                        <p className="content-tab"
                           key={ "ct" + key }>{ data }</p>
                    )
                }
                <div className="icons-tab columns">
                    {icons.map((data, key)=>
                        <div className="icon-tab column is-one-third" key={key}>
                            <img src={data.icon} alt="icon We Do" />
                            <div className="description-tab">
                                {data.title}
                            </div>
                        </div>

                    )}
                </div>

            </TabPanel>
            )
        }
    </Tabs>
);
